import(/* webpackMode: "eager" */ "/home/kundan/project/evas/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/kundan/project/evas/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/kundan/project/evas/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/kundan/project/evas/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/kundan/project/evas/src/components/block-renderer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kundan/project/evas/src/components/popular-products.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kundan/project/evas/src/components/promos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kundan/project/evas/src/components/search-form.tsx");
