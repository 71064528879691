"use client";

import { PaginatedData } from "@/types/paginated";
import { IProduct } from "@/types/product";
import Image from "next/image";
import Link from "next/link";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function PopularProducts({
    data,
}: {
    data: PaginatedData<IProduct>;
}) {
    return (
        <div
            className="section-element section-element-product-slider"
            data-theme="unset"
        >
            <Swiper
                className="swiper !py-4 !-my-4 swiper-initialized swiper-horizontal swiper-backface-hidden swiper-navigation-disabled swiper-scrollbar-disabled"
                modules={[Pagination]}
                spaceBetween={16}
                initialSlide={0}
                slidesPerView={1}
                autoplay={{ delay: 2000 }}
                pagination={{
                    enabled: true,
                    clickable: true,
                }}
                breakpoints={{
                    478: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1152: { slidesPerView: 3 },
                    1320: { slidesPerView: 4 },
                }}
                wrapperClass="swiper-wrapper flex pb-2"
            >
                {data.data.map((product: any, index: number) => {
                    return (
                        <SwiperSlide
                            className="swiper-slide py-2 w-[356px]"
                            style={{ width: 356 }}
                            key={index}
                        >
                            <div
                                className={`section-element section-element-product @container/product group relative hover:z-10 bg-theme rounded-lg`}
                                data-layout="vertical"
                                data-size="lg"
                                data-theme="default"
                            >
                                <div
                                    className={`absolute w-full h-full inset-0 shadow-base-hover opacity-0 transition-opacity group-hover:opacity-100 rounded-lg pointer-events-none`}
                                ></div>

                                <div className="product-container flex flex-col">
                                    <div className="product-image p-4 pb-0 aspect-16/9 w-full overflow-hidden rounded">
                                        <Image
                                            width={400}
                                            height={250}
                                            src={product.featured_image.url}
                                            alt={product.name}
                                            className="w-full h-full responsive-image responsive-image--contain will-change-transform group-hover:scale-105 transition-transform"
                                            loading="lazy"
                                            decoding="async"
                                        />
                                    </div>

                                    <div className="product-details flex flex-col grow gap-y-3 justify-center p-4">
                                        <div className="product-title section-element section-element-title">
                                            <div className="text-h5-alt text-pretty text-theme-fg-color">
                                                {product.name}
                                            </div>
                                        </div>

                                        <Link
                                            href={`/product/${product.slug}/${product.documentId}`}
                                            className="product-link stretched-link"
                                            aria-label="View Product"
                                        ></Link>

                                        <Link
                                            aria-label={"To Reservation"}
                                            href={`/product/${product.slug}/${product.documentId}`}
                                            className="product-link-btn relative z-10 button button-primary"
                                        >
                                            <i className="icon-cart-large-minimalistic-outline"></i>
                                            <span className="font-bold">
                                                Contact Us
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}
