"use client";

import Image from "next/image";

import {
    BlocksRenderer,
    type BlocksContent,
} from "@strapi/blocks-react-renderer";

export default function BlockRendererClient({
    content,
    limit = true,
}: {
    readonly content: BlocksContent;
    limit?: boolean;
}) {
    if (!content) return null;
    const html = limit ? content.slice(0, 1) : content;
    return (
        <BlocksRenderer
            content={html}
            blocks={{
                image: ({ image }) => {
                    return (
                        <Image
                            src={image.url}
                            width={image.width}
                            height={image.height}
                            alt={image.alternativeText || ""}
                        />
                    );
                },
            }}
        />
    );
}
